import React, { useState } from 'react';

const Image = (props) => {
  const [hover, setHover] = useState(false);
  return <></>;
  // return (
  //   <StaticQuery
  //     query={graphql`
  //       query {
  //         images: allFile {
  //           edges {
  //             node {
  //               relativePath
  //               name

  //               childImageSharp {
  //                 gatsbyImageData(blurredOptions: {},
  //                   width: 1920
  //                   placeholder: BLURRED
  //                   formats: [AUTO, WEBP, ]
  //                 )
  //               }

  //               extension
  //               publicURL
  //             }
  //           }
  //         }
  //       }
  //     `}
  //     render={(data) => {
  //       const image = data.images.edges.find((n) => {
  //         return n.node.relativePath.includes(props.filename);
  //       });
  //       if (!image) {
  //         return null;
  //       }
  //       if (
  //         image.node.childImageSharp === null &&
  //         image.node.extension === 'svg'
  //       ) {
  //         return <img src={image.node.publicURL} alt="image" loading="eager" />;
  //       }

  //       const imageData = getImage(image.node);

  //       if (props.hover) {
  //         const hoverImage = data.images.edges.find((n) => {
  //           return n.node.relativePath.includes(props.hover);
  //         });
  //         if (image.node.extension === 'svg') {
  //           return (
  //             <img
  //               {...props}
  //               onMouseOver={() => {
  //                 setHover(true);
  //               }}
  //               onMouseOut={() => {
  //                 setHover(false);
  //               }}
  //               src={hover ? hoverImage.node.publicURL : image.node.publicURL}
  //               alt="image"
  //               loading="eager"
  //             />
  //           );
  //         } else {
  //           const hoverImageData = getImage(hoverImage?.node);
  //           return (
  //             <GatsbyImage loading="lazy"
  //               {...props}
  //               alt="image"
  //               onMouseOver={() => {
  //                 setHover(true);
  //               }}
  //               onMouseOut={() => {
  //                 setHover(false);
  //               }}
  //               image={hover ? hoverImageData : imageData}
  //             ></GatsbyImage>
  //           );
  //         }
  //       }

  //       return (
  //         <GatsbyImage loading="lazy" {...props} alt="image" image={imageData}></GatsbyImage>
  //       );
  //     }}
  //   />
  // );
};
export default Image;
