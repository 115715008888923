import React from 'react';
import * as styles from './WinterOfDoingScheduleSwitch.module.scss';

export default function WinterOfDoingScheduleSwitch(props) {
  //   const dispatch = useContext(GlobalDispatchContext);
  //   const state = useContext(GlobalStateContext);

  return (
    <div
      {...props}
      className={`${styles.switchStyle} ${
        props.active == true ? styles.active : ''
      }`}
    >
      {props.children}
    </div>
  );
}
