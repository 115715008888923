import SEO from '@components/Common/SEO/index';
import Image from '@components/extra/image';
import useResizer from '@components/extra/useResizer';
import LeadCaptureVersionTwo from '@components/LeadCaptureVersionTwo/index';
import WinterOfDoingScheduleSwitch from '@components/WinterOfDoingScheduleSwitch/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadable from '@loadable/component';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '@src/context/GlobalContextProvider';
import { Link, navigate } from 'gatsby';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactHtmlParser from 'react-html-parser';
// import Slider from 'react-slick';
import { projects } from '../../misc/cwod-projects';
import * as styles from './CrioWinterOfDoing.module.scss';
import { graphql } from 'gatsby';
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';
import { Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/bundle';
import 'swiper/css';
import FooterV2 from '@components/FooterV2/index';

const CustomSnowfall = loadable(() =>
  import('../../components/CustomSnowfall/index'),
);

export default function CrioWinterOfDoing({ data }) {
  const images = data.images.nodes;
  const isMobile = useResizer();
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const swiperRef = React.useRef(null);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const typeform = 'zz81fEK8';

  const [isOpen, setOpen] = useState(false);
  const [flag, setFlag] = useState(false);
  const [navMobile, setNavMobile] = useState(false);
  const [animation, setAnimation] = useState('false');
  const [activeScheduleIndex, setActiveScheduleIndex] = useState(0);

  const wrapperRef = useRef();

  useEffect(() => {
    if (isOpen) {
      let myIframe = document.getElementById('cwod-video');
      myIframe.src =
        'https://www.youtube.com/embed/VdN6Jw4-3Dc?autoplay=1&rel=0&enablejsapi=1&origin=https%3A%2F%2Fcrio.do';
    }
  }, [isOpen]);
  useOnClickOutside(wrapperRef, () => {
    if (isOpen) {
      setOpen(false);
    }
  });

  function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = (event) => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]);
  }

  //   slidesPerPage={isMobile ? 1 : 3}
  // slidesPerScroll={1}
  const settings = {
    /* slidesPerColumn: isMobile ? 3 : 2,
		slidesPerColumnFill: 'row', */
    slidesPerView: isMobile ? 1 : 3,
    loop: true,
    spaceBetween: 30,
    speed: 300,
    grabCursor: true,
    noSwiping: false,
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: "bullets",
    // },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };
  function getSchedule(activeScheduleIndex) {
    switch (activeScheduleIndex) {
      case 0:
        return (
          <div>
            <div className="mb-3" id={styles.content}>
              <h4 className="mb-3">Warm-Up</h4>
              {/* <h6>Scope</h6> */}
              <p>
                <ul>
                  <li>
                    Complete Crio’s hands-on learning modules and pick up
                    essential developer skills (HTTP + REST API + Linux)
                  </li>
                  <li>
                    Demystify Cloud with a Cloud 101 Masterclass and a crio
                    learning module on AWS
                  </li>
                  <li>
                    Get introduced to Full-Stack development by building a
                    beautiful portfolio for yourself
                  </li>
                </ul>
              </p>
            </div>
            <div className={styles.scheduleBox}>
              <h6 className={styles.scheduleBoxHeading}>Dates & Duration</h6>{' '}
              <ul>
                <li>Jan 9th to Jan 16th</li>
                <li>10 - 15 hours</li>
                <li>Fully remote, Online, Part-time</li>
              </ul>
            </div>
            <div className={styles.scheduleBox}>
              <h6 className={styles.scheduleBoxHeading}>Benefits</h6>{' '}
              <ul>
                <li>Assured Crio Scholarships</li>
                <li>
                  Qualification to Stage 2 (Preparation) based on performance
                </li>
              </ul>
            </div>
          </div>
        );
      case 1:
        return (
          <div>
            <div className="mb-3" id={styles.content}>
              <h4 className="mb-3">Preparation</h4>
              {/* <h6>Scope</h6> */}
              <p>
                <ul>
                  <li>
                    Complete Crio’s hands-on learning modules to ramp up your
                    programming language skills
                  </li>
                  <li>
                    Get introduced to backend and frontend concepts and the tech
                    stacks required for the externship
                  </li>
                  <li>
                    Complete a timed coding challenge in any language of your
                    choice.
                  </li>
                  <li>
                    Complete a mini-project (Project details to be revealed
                    closer to this stage)
                  </li>
                </ul>
              </p>
            </div>
            <div className={styles.scheduleBox}>
              <h6 className={styles.scheduleBoxHeading}>Dates & Duration</h6>{' '}
              <ul>
                <li>Jan 17th to Jan 31st</li>
                <li>25 - 30 hours</li>
                <li>Fully remote, Online, Part-time</li>
              </ul>
            </div>
            <div className={styles.scheduleBox}>
              <h6 className={styles.scheduleBoxHeading}>Benefits</h6>{' '}
              <ul>
                <li>Additional Crio Scholarships</li>
                <li>Selection for company externships based on performance</li>
              </ul>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <div className="mb-3" id={styles.content}>
              <h4 className="mb-3">Externships (Projects)</h4>
              {/* <h6>Scope</h6> */}
              <p>
                <ul>
                  <li>
                    Get assigned to an externship project based on your
                    performance and fit
                  </li>
                  <li>
                    Deliver the project as per the project plan and demo the
                    final project to the company
                  </li>
                </ul>
              </p>
            </div>
            <div className={styles.scheduleBox}>
              <h6 className={styles.scheduleBoxHeading}>Dates & Duration</h6>{' '}
              <ul>
                <li>Feb 1st to Mar 15th</li>
                <li>80 hours</li>
                <li>Fully remote, Online, Part-time</li>
              </ul>
            </div>
            <div className={styles.scheduleBox}>
              <h6 className={styles.scheduleBoxHeading}>Benefits</h6>{' '}
              <ul>
                <li>Real project experience in top startups</li>
                <li>Externship certificate from Crio and the Tech Startup</li>
                <li>Access to Masterclass sessions</li>
              </ul>
            </div>
          </div>
        );
    }
  }

  const faqs = [
    {
      question: 'What are the prerequisites to participate?',
      answer: `This program is best suited for working professionals and student developers in their final year of engineering.`,
    },
    {
      question:
        'What are the benefits of participating in Crio Winter of Doing?',
      answer: `<ul><li>You get to work on challenging tech projects (externships) directly with top startups in India</li>
<li>You get to interact with the biggest names in India’s tech industry</li>
<li>You participate in engaging Masterclass workshops on the hottest tech topics from top engineers in India</li>
</ul>`,
    },
    {
      question:
        'Which are the participating startups providing externship opportunities?',
      answer: `CRED, Jumbotail, Recko, Slice, Groww, Vicara and AWS.`,
    },
    {
      question: 'Will I get a certificate after this?',
      answer: `Yes, absolutely.`,
    },
    {
      question: 'Is it paid?',
      answer: `Crio Winter of Doing is a completely free program. Your 100% commitment and effort are the only investments needed.`,
    },
    {
      question: 'What is the duration and schedule of the program?',
      answer: `<ul><li>The program starts on 9th January 2021</li>
<li>The program is split into 3 stages, Warm-up (1 week), Preparation (2 weeks), and Externships (Company Projects) (6 weeks)</li>
<li>Based on your performance in each stage, you will qualify for the next stage</li>
<li>You will have to spend around 10 hours each week</li>

</ul>`,
    },
    {
      question: 'Is it an online program?',
      answer: `Yes, this program is completely online (remote) and part time`,
    },
    {
      question: 'Do we work in groups or individually?',
      answer: `It's a mix of both depending on the activity involved`,
    },
  ];
  const options = (
    <ul
      onClick={() => {
        setAnimation('animate__slideOutDown');
        setTimeout(() => {
          setNavMobile(false);
        }, 700);
      }}
    >
      <li>
        <Link to="/" className="">
          Crio.Do
        </Link>
      </li>
      <li>
        {' '}
        <a href="#why-wod">Why WOD</a>
      </li>
      <li style={{ cursor: 'default' }}>
        <StaticImage
          placeholder="blurred"
          src="../../images/externship/WoD_Logo_Crio_Beaver.png"
          alt="Beaver"
          loading="lazy"
          style={{ width: '100px' }}
        ></StaticImage>
        {/* <Image
          filename="externship/WoD_Logo_Crio_Beaver.png"
          style={{ width: '100px' }}
        ></Image> */}
      </li>
      <li>
        {' '}
        <a href="#externships">Externships</a>
      </li>

      <li>
        <a href="#schedule">Schedule</a>
      </li>
    </ul>
  );

  return (
    <span className="">
      <SEO
        title="Crio Winter of Doing"
        description="Work on challenging projects for the most exciting startups in the country. Join India's largest tech externship program for developers."
      ></SEO>
      {navMobile && (
        <div
          className={`${styles.navMobile} ${animation}  flex flex-col items-center  text-center`}
        >
          <CustomSnowfall></CustomSnowfall>{' '}
          <div className="" style={{ zIndex: '2' }}>
            {' '}
            <FontAwesomeIcon
              icon={['fal', 'times']}
              size="2x"
              className="cursor-pointer"
              onClick={() => {
                setAnimation('animate__slideOutDown');
                setTimeout(() => {
                  setNavMobile(false);
                }, 700);
              }}
            />
            {options}
          </div>{' '}
          <StaticImage
            placeholder="blurred"
            src="../../images/externship/Mountain.png"
            alt="Mountain"
            loading="lazy"
            className={styles.navMountain}
          ></StaticImage>
          {/* <Image
            className={styles.navMountain}
            filename="externship/Mountain.png"
            // style={{ height: "100%", zIndex: "1" }}
          ></Image> */}
        </div>
      )}
      <div
        className={` w-full justify-center pt-3 ${styles.headerMobile}`}
        data-aos="slide-down"
        data-aos-delay="800"
        data-aos-duration="1000"
        style={{ position: 'absolute', zIndex: '9' }}
      >
        <StaticImage
          placeholder="blurred"
          src="../../images/externship/WoD_Logo_Crio_Beaver.png"
          alt="Beaver"
          loading="lazy"
          style={{ width: '100px' }}
        ></StaticImage>
        {/* <Image
          filename="externship/WoD_Logo_Crio_Beaver.png"
          style={{ width: '100px' }}
        ></Image> */}

        <div
          onClick={() => {
            setNavMobile(true);
            setAnimation('animate__slideInUp');
          }}
          style={{
            position: 'absolute',
            zIndex: '9',
            right: '0',
            marginRight: '20px',
          }}
        >
          <FontAwesomeIcon
            icon={['fas', 'bars']}
            size="2x"
            className="cursor-pointer"
          />
        </div>
      </div>
      <header
        data-aos="slide-down"
        data-aos-delay="800"
        data-aos-duration="1000"
        className={`${styles.header} justify-center`}
      >
        {options}
      </header>
      <div className="relative">
        <div className="hidden lg:block">
          <StaticImage
            layout="fullWidth"
            placeholder="blurred"
            src="../../images/externship/HeaderimageV2.png"
            alt="Hero"
            loading="eager"
            className="w-full"
          ></StaticImage>
        </div>
        <div className=" lg:hidden">
          <StaticImage
            layout="fullWidth"
            placeholder="blurred"
            src="../../images/externship/MobileHeader.png"
            alt="Hero"
            loading="eager"
            className="w-full"
          ></StaticImage>
        </div>
        <CustomSnowfall></CustomSnowfall>
        <div className={`absolute top-0 left-0 ${styles.wodHero}`}>
          <div className="row m-0 h-full w-full">
            <div className="col-lg-8  flex flex-col items-center justify-center text-center md:items-start md:text-left">
              <div
                className="bodyText2 flex justify-center font-bold md:justify-start"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <div className={styles.heroHighlight}>Top Startups</div>
                <div className={styles.heroHighlight}>
                  <span className="text-xs">●</span> Challenging Projects
                </div>
                <div className={styles.heroHighlight}>
                  <span className="text-xs">●</span> Exciting Speakers
                </div>
              </div>
              <div className="mt-3 mb-3" style={{ color: '#06131F' }}>
                <div className="mb-6">
                  <h1
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    className="mb-3 leading-tight"
                  >
                    India’s largest tech externship{' '}
                    <span className="hidden md:block"></span>program for
                    developers
                  </h1>
                  <p
                    className="font-bold"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  >
                    #GetInspiredByDoing
                  </p>
                </div>
                <p data-aos="fade-up" data-aos-duration="1000" className="mb-6">
                  Crio Winter of Doing is a one of a kind program focussed on
                  <span className="hidden md:block"></span>
                  bringing together budding engineering talent to work on
                  challenging <span className="hidden md:block"></span>
                  projects for the most exciting startups in the country.
                </p>
              </div>{' '}
              {!flag && (
                <span className="mb-4 block md:hidden">
                  {/* <div
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    <StaticImage
                      placeholder="blurred"
                      src="../../images/externship/Rest.png"
                      alt="Play"
                      loading="lazy"
                      style={{ width: '50px' }}
                    ></StaticImage>
                  </div> */}
                  {/* <Image
                    onClick={() => {
                      setOpen(true);
                    }}
                    className="cursor-pointer"
                    style={{ width: '50px' }}
                    filename="externship/Rest.png"
                    hover="externship/Hover.png"
                  ></Image> */}
                </span>
              )}
              <span className="">
                <LeadCaptureVersionTwo
                  background="#143486"
                  hoverbackground="#1B2A37"
                  color="#fff"
                  link={typeform}
                  type="CWOD"
                  location="HERO_SECTION"
                  onClick={() => {
                    setFlag(true);
                  }}
                  onCloseClick={() => {
                    setFlag(false);
                  }}
                >
                  Apply Now
                </LeadCaptureVersionTwo>
              </span>
            </div>

            <div className="col-lg-4  hidden items-center md:flex ">
              {/* <div
                onClick={() => {
                  setOpen(true);
                }}
              >
                {' '}
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/Rest.png"
                  alt="Play"
                  loading="lazy"
                  style={{ width: '100px' }}
                ></StaticImage>
              </div> */}
              {/* <Image
                onClick={() => {
                  setOpen(true);
                }}
                className="cursor-pointer"
                style={{ width: '100px' }}
                filename="externship/Rest.png"
                hover="externship/Hover.png"
              ></Image> */}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.companies}  pt-6 pb-6 md:pt-0`}>
        <div
          className=" flex flex-col items-center text-center "
          // data-aos="fade-up"
          // data-aos-delay="50"
          // data-aos-duration="1000"
        >
          <h3 className="mb-10 ">
            Featuring 9 externships, <div className="block md:hidden"></div>
            in association with
          </h3>
          <div className="row flex items-center justify-around">
            <div>
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/TopBannerLogos/Cred.png"
                alt="CRED"
                loading="lazy"
                className={styles.forYouLogo}
              ></StaticImage>
              {/* <Image
                hover="externship/TopBannerLogos/Color/Cred.png"
                filename="externship/TopBannerLogos/Cred.png"
                className={styles.forYouLogo}
              ></Image> */}
            </div>{' '}
            <div className="">
              {' '}
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/TopBannerLogos/Color/Groww.png"
                alt="Groww"
                loading="lazy"
                className={styles.forYouLogo}
              ></StaticImage>
              {/* <Image
                hover="externship/TopBannerLogos/Color/Groww.png"
                filename="externship/TopBannerLogos/Groww.png"
                className={styles.forYouLogo}
              ></Image> */}
            </div>{' '}
            <div className="">
              {' '}
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/TopBannerLogos/Color/Jumbotail.png"
                alt="Jumbotail"
                loading="lazy"
                className={styles.forYouLogo}
              ></StaticImage>
              {/* <Image
                hover="externship/TopBannerLogos/Color/Jumbotail.png"
                filename="externship/TopBannerLogos/Jumbotail.png"
                className={styles.forYouLogo}
              ></Image> */}
            </div>{' '}
            <div className="">
              {' '}
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/TopBannerLogos/Color/AWS.png"
                alt="AWS"
                loading="lazy"
                className={styles.forYouLogo}
              ></StaticImage>
              {/* <Image
                hover="externship/TopBannerLogos/Color/AWS.png"
                filename="externship/TopBannerLogos/AWS.png"
                className={styles.forYouLogo}
              ></Image> */}
            </div>{' '}
            <div className="">
              {' '}
              <Image
                hover="externship/TopBannerLogos/Color/Recko.png"
                filename="externship/TopBannerLogos/Recko.png"
                className={styles.forYouLogo}
              ></Image>
            </div>{' '}
            <div className="">
              {' '}
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/TopBannerLogos/Color/Slice.png"
                alt="Slice"
                loading="lazy"
                className={styles.forYouLogo}
              ></StaticImage>
              {/* <Image
                hover="externship/TopBannerLogos/Color/Slice.png"
                filename="externship/TopBannerLogos/Slice.png"
                className={styles.forYouLogo}
              ></Image> */}
            </div>{' '}
            <div className="">
              {' '}
              <StaticImage
                placeholder="blurred"
                src="../../images/externship/TopBannerLogos/Color/Vicara.png"
                alt="Vicara"
                loading="lazy"
                className={styles.forYouLogo}
              ></StaticImage>
              {/* <Image
                hover="externship/TopBannerLogos/Color/Vicara.png"
                filename="externship/TopBannerLogos/Vicara.png"
                className={styles.forYouLogo}
              ></Image> */}
            </div>{' '}
          </div>
        </div>
      </div>
      <span id="why-wod"></span>
      <div className={`${styles.companies} pt-10 pb-16`}>
        <div
          className="container flex flex-col text-center"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <h3 className="mb-10 ">What’s in it for you?</h3>
          <div className="row mb-20">
            <div className="col-12 col-lg-3 flex items-center text-left md:flex-col md:text-center">
              {' '}
              <div>
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/ThreeIcons/IconOne.png"
                  alt="IconOne"
                  loading="lazy"
                  style={{ width: '125px', margin: '20px' }}
                ></StaticImage>
                {/* <Image
                  filename="externship/ThreeIcons/IconOne.png"
                  style={{ width: '125px', margin: '20px' }}
                ></Image> */}
              </div>
              <p className="m-0">
                Work on challenging tech projects (externships) from exciting
                startups
              </p>
            </div>{' '}
            <div className="col-12 col-lg-3 flex items-center  text-left md:flex-col md:text-center">
              {' '}
              <div>
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/ThreeIcons/IconTwo.png"
                  alt="IconTwo"
                  loading="lazy"
                  style={{ width: '125px', margin: '20px' }}
                ></StaticImage>
                {/* <Image
                  filename="externship/ThreeIcons/IconTwo.png"
                  // className={styles.forYouLogo}
                  style={{ width: '125px', margin: '20px' }}
                ></Image> */}
              </div>
              <p className="m-0 pr-2 pl-2">
                Interact with the biggest names in India’s tech industry
              </p>
            </div>{' '}
            <div className="col-12 col-lg-3 flex items-center  text-left md:flex-col md:text-center">
              {' '}
              <div>
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/ThreeIcons/IconThree.png"
                  alt="IconThree"
                  loading="lazy"
                  style={{ width: '125px', margin: '20px' }}
                ></StaticImage>
                {/* <Image
                  filename="externship/ThreeIcons/IconThree.png"
                  // className={styles.forYouLogo}
                  style={{ width: '125px', margin: '20px' }}
                ></Image> */}
              </div>
              <p className="m-0">
                Participate in engaging Masterclass sessions from top engineers
                in the country
              </p>
            </div>{' '}
            <div className="col-12 col-lg-3 flex items-center  text-left md:flex-col md:text-center">
              {' '}
              <div>
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/ThreeIcons/IconFour.png"
                  alt="IconFour"
                  loading="lazy"
                  style={{ width: '125px', margin: '20px' }}
                ></StaticImage>
                {/* <Image
                  filename="externship/ThreeIcons/IconFour.png"
                  // className={styles.forYouLogo}
                  style={{ width: '125px', margin: '20px' }}
                ></Image> */}
              </div>
              <p className="m-0">Get discovered by Crio’s hiring partners</p>
            </div>
          </div>
          <h3 className="mb-8">
            You pay nothing. Your effort is the only investment.
          </h3>
          <div className="flex justify-center">
            <LeadCaptureVersionTwo
              data-aos="fade-up"
              data-aos-duration="1000"
              // data-aos-delay="800"
              background="#6EBAE6"
              hoverbackground="#1B2A37"
              color="#06131F"
              hovercolor="#fff"
              link={typeform}
              type="CWOD"
              location="WHATS_IN_IT_SECTION"
            >
              Apply Now
            </LeadCaptureVersionTwo>
          </div>
        </div>
      </div>
      <div className={`${styles.forYouSection} pt-16 pb-16`}>
        <div
          className="container flex flex-col text-center"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <h3 className="mb-10 ">Who is this for?</h3>
          <div className="container mb-20 text-left">
            <ul>
              <li>
                <p>
                  Crio Winter of Doing is perfect for working professionals who
                  are looking to gain stellar product development and tech
                  project building experience.{' '}
                </p>
              </li>
              <br></br>
              <li>
                {' '}
                <p>
                  It is also suitable for student developers in their final year
                  of engineering.
                </p>
              </li>
            </ul>
          </div>
          <div className="flex justify-center">
            <LeadCaptureVersionTwo
              data-aos="fade-up"
              data-aos-duration="1000"
              // data-aos-delay="800"
              background="#6EBAE6"
              hoverbackground="#1B2A37"
              color="#06131F"
              hovercolor="#fff"
              link={typeform}
              type="CWOD"
              location="WHO_IS_THIS_FOR_SECTION"
            >
              Apply Now
            </LeadCaptureVersionTwo>
          </div>
        </div>
      </div>
      <div className={`${styles.dark} pt-20`}>
        <div
          className="container flex flex-col text-center"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
          style={{ marginBottom: isMobile ? '0px' : '-150px' }}
        >
          <div className="row mb-5 text-left">
            <div className="col-12 col-lg-5 flex ">
              {' '}
              <div style={{ width: '350px' }}>
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/WhatisExternship.png"
                  alt="WhatisExternship"
                  loading="lazy"
                  className="mb-4 md:mb-0"
                ></StaticImage>
                {/* <Image
                  className="mb-4 md:mb-0"
                  filename="externship/WhatisExternship.png"
                  // className={styles.forYouLogo}
                ></Image> */}
              </div>
            </div>{' '}
            <div className="col-12 col-lg-7 flex flex-col  items-start ">
              {' '}
              <div>
                {' '}
                <h3 className=" ">What are tech externships?</h3>
                <br></br>
              </div>
              <p className="">
                Tech externships are experiential learning opportunities,
                similar to internships, but shorter and part-time, provided in
                partnership with tech companies. Crio Winter of Doing
                externships are a great opportunity for you to solve real-world
                problems in an actual tech startup setting, and learn key skills
                that will help you advance your dev career.
              </p>
            </div>{' '}
          </div>
        </div>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill={'#12202d'}
              fillOpacity="1"
              d={
                'M0,256L48,229.3C96,203,192,149,288,154.7C384,160,480,224,576,218.7C672,213,768,139,864,128C960,117,1056,171,1152,197.3C1248,224,1344,224,1392,224L1440,224L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z'
              }
              data-darkreader-inline-fill=""
            ></path>
          </svg>
        </div>
      </div>{' '}
      <span id="externships"></span>
      <div className={`${styles.startup} pt-4 pb-20 md:pt-0`}>
        <div
          className="container flex flex-col text-center"
          data-aos="fade-up"
          data-aos-delay="50"
          data-aos-duration="1000"
        >
          <h3 className="mb-8">Startup Externships</h3>
          <p className="mb-16">
            Selected developers will get an opportunity to work directly{' '}
            <div className="hidden md:block"></div>on any one of the following
            projects from exciting tech startups.
          </p>
          <div className="bodyText2 relative mb-16 ">
            {' '}
            <Swiper
              ref={swiperRef}
              {...settings}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              autoplay
              modules={[Navigation, Autoplay]}
            >
              {projects.map((project, idx) => {
                // const companyLogo = getImage(
                //   props.nestedData.companyLogo.localFile,
                // );
                const image = images.find((ele) => ele.name == project.logo);
                const companyLogo = getImage(image.childImageSharp);
                return (
                  <SwiperSlide key={`${idx}`}>
                    <span className="pr-4 pl-4 md:pr-0 md:pl-0" key={idx}>
                      <Link
                        key={idx}
                        to={project.company.replace(/\s+/g, '-').toLowerCase()}
                        className="h-full w-full"
                      >
                        <div
                          className={styles.projectCard}
                          data-aos="slide-up"
                          data-aos-delay="50"
                          data-aos-duration="1000"
                        >
                          <div className={styles.projectCardContent}>
                            <div>
                              <GatsbyImage
                                loading="lazy"
                                image={companyLogo}
                                alt={'company'}
                                style={{ width: '150px' }}
                                className="mb-2"
                              />
                              {/* <Image
                                filename={project.logo}
                                style={{ width: '150px' }}
                                className="mb-2"
                              ></Image> */}
                            </div>
                            <h6>{project.title}</h6>
                            <p className="mb-3">
                              {ReactHtmlParser(project.description)}
                            </p>

                            <div
                              style={{
                                position: 'absolute',
                                bottom: '25px',
                              }}
                            >
                              <h6 className={styles.learnMore}>Learn More </h6>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </span>
                  </SwiperSlide>
                );
              })}
              <div className="mt-10 flex w-full items-center justify-center gap-x-4">
                <div
                  onClick={() => swiperRef.current.swiper.slidePrev()}
                  ref={navigationPrevRef}
                  className=" top-1/2 left-0 z-10 -translate-x-1/2 -translate-y-1/2 transform  cursor-pointer hover:opacity-75"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-left']}
                  ></FontAwesomeIcon>
                </div>{' '}
                <div
                  onClick={() => swiperRef.current.swiper.slideNext()}
                  ref={navigationNextRef}
                  className=" top-1/2 right-0 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75"
                >
                  <FontAwesomeIcon
                    icon={['fas', 'arrow-right']}
                  ></FontAwesomeIcon>
                </div>
              </div>
            </Swiper>
          </div>{' '}
          <div className="flex justify-center">
            <LeadCaptureVersionTwo
              background="#6EBAE6"
              hoverbackground="#1B2A37"
              color="#06131F"
              hovercolor="#fff"
              link={typeform}
              type="CWOD"
              location="STARTUP_EXTERNSHIPS_SECTION"
            >
              Apply Now
            </LeadCaptureVersionTwo>
          </div>
        </div>
      </div>
      <div className={`${styles.companies} pt-16 pb-16`}>
        <div className="container flex flex-col text-center">
          <h3 className="mb-14 ">Speakers</h3>
          <div className="row mb-5 flex justify-center">
            <div className="col-12 col-lg-4 mb-4 flex items-center justify-around md:flex-col">
              <div className={styles.speakerCard}>
                <div>
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/externship/SpeakerPhotos/BinnyBansal.png"
                    alt="BinnyBansal"
                    loading="lazy"
                    style={{ height: '300px' }}
                  ></StaticImage>
                  {/* <Image
                    filename="externship/SpeakerPhotos/BinnyBansal.png"
                    style={{ height: '300px' }}
                  ></Image> */}
                </div>
                <div className={styles.speakerCardContent}>
                  <h4 className="">Kickoff</h4>
                  <p style={{ color: '#465665' }}>with</p>
                  <h4 className={styles.speakerTitle}>Binny Bansal</h4>
                  <p className={styles.speakerDescription}>
                    Co-Founder, xto10x <br></br>Co-Founder, Flipkart
                  </p>
                  <div className="mt-5 flex">
                    <a
                      href="https://www.xto10x.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/externship/SpeakerPhotos/XToTenX.png"
                        alt="XToTenX"
                        loading="lazy"
                        className={styles.speakerCompany}
                      ></StaticImage>
                      {/* <Image
                        filename="externship/SpeakerPhotos/XToTenX.png"
                        className={styles.speakerCompany}
                      ></Image>{' '} */}
                    </a>
                    <a
                      href="https://www.flipkart.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/externship/SpeakerPhotos/Flipkart.png"
                        alt="Flipkart"
                        loading="lazy"
                        className={styles.speakerCompany}
                      ></StaticImage>
                      {/* <Image
                        filename="externship/SpeakerPhotos/Flipkart.png"
                        className={styles.speakerCompany}
                      ></Image>{' '} */}
                    </a>
                  </div>
                </div>
              </div>{' '}
            </div>{' '}
            <div className="col-12 col-lg-4 mb-4 flex  items-center justify-around md:flex-col">
              {' '}
              <div className={styles.speakerCard}>
                <div>
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/externship/SpeakerPhotos/VivekRavishankar.png"
                    alt="Vivek"
                    loading="lazy"
                    style={{ height: '300px' }}
                  ></StaticImage>
                  {/* <Image
                    filename="externship/SpeakerPhotos/VivekRavishankar.png"
                    style={{ height: '300px' }}
                  ></Image> */}
                </div>
                <div className={styles.speakerCardContent}>
                  <h4 className="">Fireside Chat</h4>
                  <p style={{ color: '#465665' }}>with</p>
                  <h4 className={styles.speakerTitle}>Vivek Ravisankar</h4>
                  <p className={styles.speakerDescription}>
                    Co-Founder<br></br> HackerRank
                  </p>
                  <div className="mt-5  flex">
                    <a
                      href="https://www.hackerrank.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/externship/SpeakerPhotos/Hackerank.png"
                        alt="Hackerank"
                        loading="lazy"
                        className={styles.speakerCompany}
                      ></StaticImage>
                      {/* <Image
                        filename="externship/SpeakerPhotos/Hackerank.png"
                        className={styles.speakerCompany}
                      ></Image>{' '} */}
                    </a>
                  </div>
                </div>
              </div>{' '}
            </div>{' '}
            <div className="col-12 col-lg-4 mb-4 flex  items-center justify-around md:flex-col">
              {' '}
              <div className={styles.speakerCard}>
                <div>
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/externship/SpeakerPhotos/KaranMV.png"
                    alt="KaranMV"
                    loading="lazy"
                    style={{ height: '300px' }}
                  ></StaticImage>

                  {/* <Image
                    filename="externship/SpeakerPhotos/KaranMV.png"
                    style={{ height: '300px' }}
                  ></Image> */}
                </div>
                <div className={styles.speakerCardContent}>
                  <h4 className="">Expert Chat</h4>
                  <p style={{ color: '#465665' }}>with</p>
                  <h4 className={styles.speakerTitle}>Karan MV</h4>
                  <p className={styles.speakerDescription}>
                    Developer Relations<br></br> GitHub
                  </p>
                  <div className="mt-5  flex">
                    <a
                      href="https://www.github.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <StaticImage
                        placeholder="blurred"
                        src="../../images/externship/SpeakerPhotos/Github.png"
                        alt="Github"
                        loading="lazy"
                        className={styles.speakerCompany}
                      ></StaticImage>
                      {/* <Image
                        filename="externship/SpeakerPhotos/Github.png"
                        className={styles.speakerCompany}
                      ></Image>{' '} */}
                    </a>
                  </div>
                </div>
              </div>{' '}
            </div>
          </div>
        </div>
      </div>{' '}
      <span id="schedule"></span>
      <div className={`${styles.dark} pt-20 pb-20 `}>
        <div className=" mb-5 flex flex-col">
          <div className="row  text-left">
            <div className="col-lg-4 flex hidden items-center md:flex  md:flex-col">
              {' '}
              {activeScheduleIndex == 0 ? (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/StageMountain/Stage-1.png"
                  alt="Stage 1 "
                  loading="lazy"
                  className={styles.mountainStage}
                ></StaticImage>
              ) : activeScheduleIndex == 1 ? (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/StageMountain/Stage-2.png"
                  alt="Stage 2 "
                  loading="lazy"
                  className={styles.mountainStage}
                ></StaticImage>
              ) : activeScheduleIndex == 2 ? (
                <StaticImage
                  placeholder="blurred"
                  src="../../images/externship/StageMountain/Stage-3.png"
                  alt="Stage 3"
                  loading="lazy"
                  className={styles.mountainStage}
                ></StaticImage>
              ) : (
                ''
              )}
              {/* // <Image
              //   filename={
              //     'externship/StageMountain/Stage-' +
              //     (activeScheduleIndex + 1) +
              //     '.png'
              //   }
              //   className={styles.mountainStage}
              // ></Image> */}
            </div>{' '}
            <div className="col-lg-1 hidden md:block"></div>
            <div
              className={`col-12 col-lg-5  text-left  md:flex-col ${styles.schedule}`}
            >
              {' '}
              <h3 className="mb-4">Schedule</h3>
              <div className={`${styles.switcher} mb-4`} id="switch-parent">
                {Array(3)
                  .fill()
                  .map((ele, idx) => (
                    <WinterOfDoingScheduleSwitch
                      key={idx}
                      active={activeScheduleIndex == idx ? true : false}
                      onClick={() => setActiveScheduleIndex(idx)}
                    >
                      Stage {idx + 1}{' '}
                    </WinterOfDoingScheduleSwitch>
                  ))}
              </div>
              {getSchedule(activeScheduleIndex)}
            </div>{' '}
          </div>
        </div>{' '}
        <div className="flex justify-center">
          <LeadCaptureVersionTwo
            background="#6EBAE6"
            hoverbackground="#1B2A37"
            color="#06131F"
            hovercolor="#fff"
            link={typeform}
            type="CWOD"
            location="SCHEDULE_SECTION"
          >
            Apply Now
          </LeadCaptureVersionTwo>
        </div>
      </div>{' '}
      <div className={`${styles.partners} pt-20 pb-16`}>
        <div className="container flex flex-col text-center">
          <div className="row mb-5">
            <div className="col-12 col-lg-12 mb-3 flex flex-col items-center justify-start">
              {' '}
              <h3 className="mb-4">Technology Partner</h3>
              <div className="flex justify-center">
                <div>
                  <a
                    href="https://aws.amazon.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      placeholder="blurred"
                      src="../../images/externship/PartnerLogos/Color/AWS.png"
                      alt="AWS"
                      loading="lazy"
                      className={styles.forYouLogo}
                    ></StaticImage>

                    {/* <Image
                      // hover="externship/PartnerLogos/Color/AWS.png"
                      filename="externship/PartnerLogos/Color/AWS.png"
                      className={styles.forYouLogo}
                    ></Image> */}
                  </a>
                </div>{' '}
                <div>
                  <a
                    href="https://www.hackerrank.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      placeholder="blurred"
                      src="../../images/externship/HR_white.png"
                      alt="HR_white"
                      loading="lazy"
                      className={styles.forYouLogo}
                    ></StaticImage>
                    {/* <Image
                      hover="externship/HR_white.png"
                      filename="externship/HR_white.png"
                      className={styles.forYouLogo}
                    ></Image> */}
                  </a>
                </div>{' '}
                <div>
                  <a
                    href="https://www.github.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StaticImage
                      placeholder="blurred"
                      src="../../images/externship/PartnerLogos/Color/Github.png"
                      alt="Github"
                      loading="lazy"
                      className={styles.forYouLogo}
                    ></StaticImage>
                    {/* <Image
                      // hover="externship/HR_white.png"
                      filename="externship/PartnerLogos/Color/Github.png"
                      className={styles.forYouLogo}
                    ></Image> */}
                  </a>
                </div>
              </div>
            </div>{' '}
            <div className="col-12 col-lg-12 flex flex-col  items-center justify-start">
              {' '}
              <h3 className="mb-4 ">Media Partner</h3>
              <div>
                <a
                  href="https://yourstory.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <StaticImage
                    placeholder="blurred"
                    src="../../images/externship/PartnerLogos/Color/Yourstory.png"
                    alt="GiYourstorythub"
                    loading="lazy"
                    className={styles.forYouLogo}
                  ></StaticImage>

                  {/* <Image
                    // hover="externship/PartnerLogos/Color/Yourstory.png"
                    filename="externship/PartnerLogos/Color/Yourstory.png"
                    className={styles.forYouLogo}
                  ></Image> */}
                </a>
              </div>
            </div>{' '}
          </div>
        </div>
      </div>
      <div className="relative overflow-hidden md:h-[250px]">
        <StaticImage
          layout="fullWidth"
          placeholder="blurred"
          src="../../images/externship/banner.png"
          alt="FAQ"
          loading="eager"
          className="w-full  py-10 md:py-0"
        ></StaticImage>

        <div className="container absolute top-0 left-0 right-0 flex  h-full items-center justify-center ">
          <div className="row flex flex-col items-center justify-center">
            <h2 style={{ color: '#06131F' }} className="mb-3">
              {' '}
              #GetInspiredByDoing
            </h2>
            <LeadCaptureVersionTwo
              background="#143486"
              hoverbackground="#1B2A37"
              color="#fff"
              link={typeform}
              type="CWOD"
              location="BOTTOM_BANNER"
            >
              Apply Now
            </LeadCaptureVersionTwo>
          </div>
        </div>
      </div>
      <div className={`${styles.faqs} pt-20 pb-20`}>
        <div className="container flex flex-col ">
          <h3 className="mb-16 text-center">F.A.Q.s</h3>

          <div className="row mb-10">
            {' '}
            <div className="col-12 flex flex-col items-center justify-center ">
              <div className="container p-0 " style={{ marginTop: '-30px' }}>
                <div className={`${styles.accordion} custom mb-4`}>
                  <ul>
                    <li>
                      <input type="checkbox" defaultChecked />
                      <i />
                      <div className="subtitle1 font-light " id={styles.title}>
                        How can I participate in Crio Winter of Doing?
                      </div>

                      <p className="font-light">
                        {' '}
                        <div className={styles.accordionContent}>
                          <div className={styles.text}>
                            Fill this{' '}
                            <a
                              onClick={() => {
                                navigate('/registration', {
                                  state: {
                                    link: typeform,
                                    // logo: "Crio_Light.png",
                                  },
                                });
                              }}
                            >
                              application
                            </a>{' '}
                            in as much detail as possible. The number of slots
                            are limited. Apply as soon as possible.
                          </div>
                        </div>
                      </p>
                    </li>
                    {faqs.map((faq, idx) => (
                      <li key={idx}>
                        <input type="checkbox" defaultChecked />
                        <i />
                        <div
                          className="subtitle1 font-light "
                          id={styles.title}
                        >
                          {faq.question}{' '}
                        </div>

                        <p className="font-light">
                          {' '}
                          <div className={styles.accordionContent}>
                            <div className={styles.text}>
                              {ReactHtmlParser(faq.answer)}
                            </div>
                          </div>
                        </p>
                      </li>
                    ))}{' '}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer></Footer> */}
      <FooterV2 />
    </span>
  );
}

// export default AboutUsPage;

export const pageQuery = graphql`
  query {
    ...externshipCompaniesFragment
  }
`;
